/*!

=========================================================
* jkicks - v1.0.0
=========================================================
* Coded by Ecrubit
*/

$asset-base-path: '../../../../assets' !default;

// Core

@import "jkicks/custom/functions";
@import "~bootstrap/scss/functions";

@import "jkicks/custom/variables";
@import "~bootstrap/scss/variables";

@import "jkicks/custom/mixins";
@import "~bootstrap/scss/mixins";

// Bootstrap components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Custom components

@import "jkicks/custom/alerts.scss";
@import "jkicks/custom/buttons.scss";
@import "jkicks/custom/dropdown.scss";
@import "jkicks/custom/footer.scss";
@import "jkicks/custom/forms.scss";
@import "jkicks/custom/images.scss";
@import "jkicks/custom/modal.scss";
@import "jkicks/custom/navbar.scss";
@import "jkicks/custom/type.scss";
@import "jkicks/custom/icons.scss";
@import "jkicks/custom/tables";
@import "jkicks/custom/checkboxes-radio";
@import "jkicks/custom/fixed-plugin";
@import "jkicks/custom/pagination.scss";
@import "jkicks/custom/misc.scss";
@import "jkicks/custom/rtl.scss";
@import "jkicks/custom/input-group.scss";
@import "jkicks/custom/example-pages.scss";
@import "jkicks/custom/progress.scss";
@import "jkicks/custom/badge.scss";
@import "jkicks/custom/pills.scss";
@import "jkicks/custom/tabs.scss";
@import "jkicks/custom/info-areas.scss";

// Sections
@import "jkicks/custom/sections.scss";

// Vendor / Plugins

@import "jkicks/custom/vendor/plugin-perfect-scrollbar.scss";
@import "jkicks/custom/vendor/plugin-animate-bootstrap-notify.scss";
@import "jkicks/custom/vendor/plugin-bootstrap-switch.scss";
@import "jkicks/custom/vendor/plugin-nouislider.scss";
@import "jkicks/custom/vendor/plugin-datetimepicker.scss";

// Cards

@import "jkicks/custom/card";
@import "jkicks/custom/cards/card-chart";
@import "jkicks/custom/cards/card-map";
@import "jkicks/custom/cards/card-user";
@import "jkicks/custom/cards/card-task";
@import "jkicks/custom/cards/card-plain";
@import "jkicks/custom/cards/card-register";
@import "jkicks/custom/cards/card-stats";
@import "jkicks/custom/cards/card-chart";

// React Differences

@import "jkicks/react/react-differences";

@import "custom.scss";
@import "mobile.scss";